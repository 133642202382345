
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import ItemsList from '@/components/ItemsList.vue';
import ItemForm from '@/components/ItemForm.vue';

export default {
  components: { Breadcrumbs, ItemsList, ItemForm },
  setup() {
    return {};
  },
};
