import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyD9nagJdaYw2T8VcU0j1fJsRcl_BblgCms',
  authDomain: 'alaska-b64af.firebaseapp.com',
  databaseURL: 'https://alaska-b64af.firebaseio.com',
  projectId: 'alaska-b64af',
  storageBucket: 'alaska-b64af.appspot.com',
  messagingSenderId: '584736122779',
  appId: '1:584736122779:web:c104fa97494c6a7160a33e',
  measurementId: 'G-05VMJ7DCSF',
};

// init firebase
firebase.initializeApp(firebaseConfig);

// init services
const projectFirestore = firebase.firestore();
const projectAuth = firebase.auth();
const projectStorage = firebase.storage();

// timestamp
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { projectAuth, projectFirestore, projectStorage, timestamp };
