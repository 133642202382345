<template>
  <nav class="navbar has-shadow is-white" v-if="user">
    <div class="navbar-brand">
      <div class="navbar-item">
        <router-link class="button is-light" :to="{ name: 'Home' }">
          Acacia Home
        </router-link>
      </div>
      <div
        class="navbar-burger"
        :class="{ 'is-active': showNav }"
        @click="showNav = !showNav"
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <div class="navbar-menu" :class="{ 'is-active': showNav }" id="nav-links">
      <div class="navbar-start">
        <router-link class="navbar-item" :to="{ name: 'Items' }">
          Items
        </router-link>
        <router-link class="navbar-item" :to="{ name: 'Sales' }">
          Sales
        </router-link>
        <router-link class="navbar-item" :to="{ name: 'Purchase' }">
          Purchase
        </router-link>
        <router-link class="navbar-item" :to="{ name: 'Accounting' }">
          Accounting
        </router-link>
        <router-link class="navbar-item" :to="{ name: 'Contacts' }">
          Contacts
        </router-link>
      </div>
      <div class="navbar-end">
        <router-link class="navbar-item" :to="{ name: 'Admin' }">
          {{ user.email }}
        </router-link>
        <router-link
          class="navbar-item"
          :to="{ name: 'Login' }"
          @click="handleLogout"
          >Log Out</router-link
        >
      </div>
    </div>
  </nav>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import getUser from '@/services/getUser';
import useLogout from '@/services/useLogout';

export default defineComponent({
  setup() {
    const router = useRouter();
    const route = useRoute();
    const user = getUser();
    const { logout } = useLogout();

    const handleLogout = async () => {
      await logout();
      router.push({ name: 'Login' });
    };

    return { handleLogout, user };
  },
  data() {
    return {
      showNav: false,
    };
  },
});
</script>

<style></style>
