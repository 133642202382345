<template>
  <breadcrumbs></breadcrumbs>
  <div class="home">
    <h1>Contacts</h1>
    <Counter msg="Welcome to Your Vue.js + TypeScript App" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Counter from '@/components/Counter.vue'; // @ is an alias to /src
import Breadcrumbs from '@/components/Breadcrumbs.vue';

@Options({
  components: {
    Counter,
    Breadcrumbs,
  },
})
export default class Home extends Vue {}
</script>
