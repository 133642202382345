
import { Options, Vue } from "vue-class-component";
import Counter from "@/components/Counter.vue"; // @ is an alias to /src

@Options({
  components: {
    Counter,
  },
})
export default class Home extends Vue {}
