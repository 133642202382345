
import { Options, Vue } from 'vue-class-component';
import Counter from '@/components/Counter.vue'; // @ is an alias to /src
import Breadcrumbs from '@/components/Breadcrumbs.vue';

@Options({
  components: {
    Counter,
    Breadcrumbs,
  },
})
export default class Home extends Vue {}
