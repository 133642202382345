<template>
  <Breadcrumbs />
  <div class="section">
    <h1 class="title">Sales</h1>
    <div class="container">
      List
    </div>
  </div>
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';

export default {
  name: 'Sales',
  components: { Breadcrumbs },
  setup() {
    return {};
  },
};
</script>
