<template>
  <Breadcrumbs />
</template>

<script lang="ts">
import Breadcrumbs from '@/components/Breadcrumbs.vue';
export default {
  components: { Breadcrumbs },
};
</script>

<style></style>
