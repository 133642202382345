<template>
  <!-- Breadcrumbs -->
  <div class="section pt-4 pb-0">
    <nav class="breadcrumb">
      <ul class="container">
        <li>
          <a class="has-text-grey">{{ route.name }}</a>
        </li>
        <li class="is-active"><a>New Item</a></li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { useRoute } from 'vue-router';
export default {
  setup() {
    const route = useRoute();
    return { route };
  },
};
</script>

<style></style>
