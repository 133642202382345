
import { defineComponent } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import getUser from '@/services/getUser';
import useLogout from '@/services/useLogout';

export default defineComponent({
  setup() {
    const router = useRouter();
    const route = useRoute();
    const user = getUser();
    const { logout } = useLogout();

    const handleLogout = async () => {
      await logout();
      router.push({ name: 'Login' });
    };

    return { handleLogout, user };
  },
  data() {
    return {
      showNav: false,
    };
  },
});
