<template>
  <section class="hero">
    <div class="hero-body">
      <div class="container has-text-centered">
        <form class="column is-4 is-offset-4" @submit.prevent="handleSubmit">
          <h3 class="title ">Macada</h3>
          <h4 class="subtitle ">Login</h4>
          <hr class="login-hr" />
          <div class="field">
            <p class="control">
              <input
                type="email"
                class="input"
                placeholder="Email"
                v-model="email"
                ref="el"
              />
            </p>
          </div>
          <div class="field">
            <p class="control">
              <input
                type="password"
                class="input"
                placeholder="Password"
                v-model="password"
              />
            </p>
          </div>
          <button class="button is-fullwidth">Login</button>
        </form>
        <div v-if="error" class="error">{{ error }}</div>
      </div>
    </div>
  </section>
</template>

<script>
import useLogin from '@/services/useLogin';
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';

export default {
  setup() {
    const { error, login } = useLogin();
    const email = ref('');
    const password = ref('');
    const router = useRouter();

    const el = ref();

    const handleSubmit = async () => {
      const res = await login(email.value, password.value);
      if (!error.value) {
        console.log('user logged in');
        router.replace({ name: 'Home' });
      }
    };

    onMounted(() => {
      el.value.focus();
    });

    return { el, email, password, handleSubmit, error };
  },
};
</script>

<style></style>
