<template>
  <h1>{{ msg }}</h1>
  <h4>{{ state.count }} x 2 = {{ getters.times2 }}</h4>
  <button class="btn btn-success block m-2" @click="inc(1)">Inc</button>
</template>

<script>
import { defineComponent } from '@vue/runtime-core';
import counterStore from '@/stores/counter';

export default defineComponent({
  props: { msg: String },
  setup() {
    const { state, getters, inc } = counterStore;

    return { state, getters, inc };
  },
});
</script>

<style></style>
