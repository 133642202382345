<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png" />
    <Counter msg="Welcome to Your Vue.js + TypeScript App" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Counter from "@/components/Counter.vue"; // @ is an alias to /src

@Options({
  components: {
    Counter,
  },
})
export default class Home extends Vue {}
</script>
