
import Breadcrumbs from '@/components/Breadcrumbs.vue';

export default {
  name: 'Sales',
  components: { Breadcrumbs },
  setup() {
    return {};
  },
};
