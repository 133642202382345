import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Login from '../views/Login.vue';
import Home from '../views/Home.vue';
import Items from '../views/Items.vue';
import Sales from '../views/Sales.vue';
import Purchase from '../views/Purchase.vue';
import Contacts from '../views/Contacts.vue';
import Accounting from '../views/Accounting.vue';

// auth for route guard
import { projectAuth } from '@/firebase/config';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/items',
    name: 'Items',
    component: Items,
  },
  {
    path: '/sales',
    name: 'Sales',
    component: Sales,
  },
  {
    path: '/purchase',
    name: 'Purchase',
    component: Purchase,
  },
  {
    path: '/accounting',
    name: 'Accounting',
    component: Accounting,
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts,
  },
  {
    path: '/admin',
    name: 'Admin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "admin" */ '../views/Admin.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  let user = projectAuth.currentUser;
  if (to.name !== 'Login' && !user) next({ name: 'Login' });
  else if (to.name == 'Login' && user) next({ name: 'Home' });
  else next();
});

export default router;
